<template>
  <div>
    <div v-if="loading">
      <clip-loader :loading="loading" color="primary"></clip-loader>
    </div>
    <div v-else>
      <vx-card class="mb-4" :title="productName">
        <div slot="no-body">
          <vs-tabs>
            <vs-tab label="Informations générales">
              <div>
                <aab-vie-subscribe-general-information
                  :crrModuleName="crrModuleName"
                  :item="item"
                  :dataProject="currentSuscribeData"
                ></aab-vie-subscribe-general-information>
              </div>
            </vs-tab>
            <vs-tab label="Liste des quittances">
              <div>
                <aab-vie-subscribe-receipt
                  v-on:onDisplayQuittanceDetail="onDisplayQuittanceDetail"
                  :crrModuleName="crrModuleName"
                  :item="item"
                  :dataProject="currentSuscribeData"
                ></aab-vie-subscribe-receipt>
              </div> 
            </vs-tab>
            <vs-tab label="Evolution du contrat">
              <div>
                <aab-vie-subscribe-contrat-evolution
                  :crrModuleName="crrModuleName"
                  :item="item" 
                  :dataProject="currentSuscribeData"
                ></aab-vie-subscribe-contrat-evolution>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default { 
  props: {
    crrModuleName: String,
    item: String,
  },
  components: {
    ClipLoader,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters("suscribe", ["currentSuscribeData",]),
    productName() {
      if(this.crrModuleName == "AHR") {
        return "Atlantique Horizon Retraite"
      } else if (this.crrModuleName == "AAE") {
        return "Atlantique Avenir Enfant"
      }
    },
  },
  methods: {
    ...mapActions("suscribe", ["applyGetProject"]),
    onDisplayQuittanceDetail(isClose) {
      this.$emit("onDisplayQuittance", isClose);
    },
    async getProject(id) {
      let data = {
        projectId: id,
      }
 
      this.loading = true;

      try {
        await this.applyGetProject(data);

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
  watch: {
    item() {
      this.getProject(this.item)
    },
  },
};
</script>
